import { useQuery } from '@tanstack/react-query';

import { fetchCategoriesReturns } from '~/api/category';

const useFetchCategoriesReturns = (months: number[], categories: number[]) => {
  const query = useQuery({
    queryKey: ['categories', 'returns', months, categories],
    queryFn: () =>
      fetchCategoriesReturns({
        months,
        categories,
      }),
    enabled: categories.length > 0 && months.length > 0,
  });

  return query;
};

export default useFetchCategoriesReturns;
