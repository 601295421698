import { useQuery } from '@tanstack/react-query';

import { fetchCategoriesMarketCapQuotation } from '~/api/category';

const useFetchCategoriesMarketCapQuotation = (interval: '1day' | '1week', categories: number[]) => {
  const query = useQuery({
    queryKey: ['categories', 'market-cap', interval, categories],
    queryFn: () =>
      fetchCategoriesMarketCapQuotation({
        interval,
        categories,
      }),
    enabled: categories.length > 0,
  });

  return query;
};

export default useFetchCategoriesMarketCapQuotation;
