import type { FC } from 'react';
import { Bar } from 'react-chartjs-2';

import { Card, CardBody, CardHeader } from '@nextui-org/react';

interface Props {
  targetDate: string;
  title: string;
  returns: {
    months: number;
    date: string;
    return: number;
  }[];
}

const ReturnCard: FC<Props> = ({ title, targetDate, returns }) => {
  return (
    <Card className="py-4 font-bold border-2 border-green-400">
      <CardHeader className="pb-4 pt-2 px-4 flex-col items-start">
        <h3 className="font-bold text-2xl">{title}</h3>
        <span className="text-default-500">{`기준날짜 ${targetDate}`}</span>
      </CardHeader>
      <CardBody className="overflow-visible py-2">
        <Bar
          data={{
            labels: returns
              .slice()
              .reverse()
              .map((r) => `${r.months}개월`),
            datasets: [
              {
                label: 'n개월 전에 샀을 때 수익률',
                data: returns
                  .slice()
                  .reverse()
                  .map((r) => r.return),
              },
            ],
          }}
          options={{
            indexAxis: 'x',
            scales: {
              y: {
                ticks: {
                  callback(value) {
                    return `${value}%`;
                  },
                },
              },
            },
          }}
        />
      </CardBody>
    </Card>
  );
};

export default ReturnCard;
